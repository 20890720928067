import React, { useState, useEffect, createContext } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import '../App.css';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { setGlobalState, useGlobalState } from "../state";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Backdrop, CircularProgress } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';



export default function CallExclusionForm() {
    const [loanNumInput, setLoanNumInput] = useState("");
    const [outboundDataObject, setOutboundDataObject] = useGlobalState('outboundDataObject')
    const [inputArray, setInputArray] = useGlobalState('exclusionInputArray');
    const [editMode, setEditMode] = useGlobalState('editMode')
    const [disableSave, setDisableSave] = useGlobalState('disableSave');
    const [callExclusionBody, setCallExclusionBody] = useGlobalState('callExclusionBody');
    const [open, setOpen] = React.useState(false);
    const [amountErr, setAmountErr] = useGlobalState('amountErr')
    const [exclusionErr, setExclusionErr] = React.useState(false);
    const [excludedArr, setExcludedArr] = useState([]);
    const [notFoundDialer, setNotFoundDialer] = useGlobalState('notFoundDialer')
    const [notFoundArr, setNotFoundArr] = useState([]);
    const [validationView, setValidationView] = React.useState(false);
    const [formattedArr, setFormattedArr] = useState([]);
    const [validationChecker, setValidationChecker] = useGlobalState('validationChecker')
    const [validationCounter, setValidationCounter] = React.useState(false);
    const [errorChecker, setErrorChecker] = React.useState(false);
    const [loanNumArr, setLoanNumArr] = useState([]);
    const [exclusionDisableSave, setExclusionDisableSave] = useGlobalState('exclusionDisableSave');
    const [input, setInput] = useState('');
    const [exclusionInput, setExclusionInput] = useGlobalState('exclusionInput')
    const [validArr, setValidArr] = useState([]);
    const [invalidArr, setInvalidArr] = useGlobalState('invalidArr')
    const [validationPassed, setValidationPassed] = React.useState(false);
    const [newValues, setNewValues] = useGlobalState('newValues')
    const [isLoading, setIsLoading] = useState(false)
    const [alertModal, setAlertModal] = useGlobalState('alertModal')
    const [errorArray, setErrorArray] = useGlobalState('errorArray')


    useEffect(() => {
      if(inputArray.length > 100){
        setDisableSave(true)
        setAmountErr(true)
      } else if(inputArray.length < 100){
        setAmountErr(false)
        setDisableSave(false)
       } 
    });
      
       async function onInputChange(e){
        setLoanNumInput(e.target.value)
        setInputArray(e.target.value.split(','))
        setExclusionInput(e.target.value)
       }
       const handleClickOpen = () => {
        setAlertModal(true);
      };
    
      const handleModalClose = () => {
        setErrorArray([])
        setAlertModal(false);
      };

       const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAmountErr(false);
        setExclusionErr(false)
        setNotFoundDialer(false)
      };  

    return (
    <div>
    <CssBaseline />
     <Grid container xs={12}>
     <Typography variant="h6">Call Exclusion Form</Typography>
     <Grid xs={12}>
      <Typography sx={{color:'black'}} variant="subtitle2">Only 100 comma delimited submissions are able to be submitted at once.</Typography>
    </Grid>
      <Grid xs={12}>   
      <form noValidate autoComplete="off">
      <FormControl sx={{ width: '100%', marginTop:'3%' }}>  
      <TextField
        error= {false}
        disabled={!editMode}
        value={inputArray}
        fullWidth   
        id="standard-textarea"
        label="Enter CAF Loan Number"
        placeholder="Call Exclusion Loan Number Input"
        multiline
        rows={6}
        variant="standard"
        onChange={onInputChange}
          />
      </FormControl>
      </form>
      </Grid>               
      </Grid>
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
      >
      <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={alertModal}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >     
      <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="filled" severity="warning">
      <Typography sx={{color:'white'}} variant="subtitle1">These agreement numbers were not processed, please verify numbers are correct and try again. if problem persists, please contact CCT admin.</Typography>
      </Alert>
      </Stack>  
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography sx={{color:'black'}} variant="h6">Loan Numbers:</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {errorArray.map((value) => (
               <ListItem
              key={value}
              disableGutters
        >
          <ListItemText sx={{ color: 'black' }} primary={`${value}`} />
          <WarningAmberIcon sx={{color: 'red'}} />
        </ListItem>
      ))}
    </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} autoFocus>
            Return To Exclusion Form
          </Button>
        </DialogActions>
      </Dialog>
    </div>     
    )
}
